import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [0];

export const dictionary = {
		"/accept-invite": [~43],
		"/error": [44],
		"/healthz": [45],
		"/login": [46],
		"/login/error": [47],
		"/onboarding": [48],
		"/[org]": [12,[2]],
		"/[org]/inbox/[[messageId]]": [13,[2]],
		"/[org]/integrations": [14,[2]],
		"/[org]/integrations/[slug]": [15,[2,3]],
		"/[org]/integrations/[slug]/credentials": [16,[2,3]],
		"/[org]/link/github-app": [17],
		"/[org]/people/(list)": [18,[2,4]],
		"/[org]/people/invite": [21,[2]],
		"/[org]/people/my-profile": [22,[2]],
		"/[org]/people/(list)/pending": [19,[2,4]],
		"/[org]/people/[personId]": [20,[2]],
		"/[org]/projects/(list)": [23,[2,5]],
		"/[org]/projects/(list)/drafts": [24,[2,5]],
		"/[org]/projects/new": [29,[2]],
		"/[org]/projects/[projectId]": [25,[2,6]],
		"/[org]/projects/[projectId]/edit": [26,[2]],
		"/[org]/projects/[projectId]/environments/[[environmentId]]": [27,[2,6,7]],
		"/[org]/projects/[projectId]/events": [28,[2,6]],
		"/[org]/recipes/(list)": [30,[2,8]],
		"/[org]/recipes/(list)/archived": [31,[2,8]],
		"/[org]/recipes/(list)/drafts": [32,[2,8]],
		"/[org]/recipes/[recipeId]/[[stepId]]": [33,[2,9]],
		"/[org]/resources": [34,[2]],
		"/[org]/resources/policies": [36,[2,11]],
		"/[org]/resources/policies/[appId]": [37,[2,11]],
		"/[org]/resources/provisioning": [38,[2]],
		"/[org]/resources/[resourceId]": [35,[2,10]],
		"/[org]/settings": [39,[2]],
		"/[org]/teams": [40,[2]],
		"/[org]/teams/create": [42,[2]],
		"/[org]/teams/[teamId]": [41,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';