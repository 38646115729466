import * as Sentry from "@sentry/sveltekit";
import { dev } from "$app/environment";
import { SENTRY_DSN, SENTRY_ENVIRONMENT } from "$lib/env.public";

export const opts: Sentry.NodeOptions | Sentry.BrowserOptions = {
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  beforeSend: (event, hint) => {
    // Drop error events in local development in favor of console.error.
    if (dev) {
      console.error(hint.originalException ?? hint.syntheticException);
      return null;
    }
    return event;
  },
};
